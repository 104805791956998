export enum UserRoles {
  Standard,
  Admin,
  SuperAdmin,
}

export enum Device {
  ThisDevice,
  OtherDevice,
}

export enum Language {
  Svenska,
  English,
}
