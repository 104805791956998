import axios, { AxiosError } from "axios";
import { IUser } from "../types/interfaces";

export const checkCurrentUser = async (): Promise<IUser | undefined> => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/users/current`
    );

    return user.data;
  } catch (error) {
    throw new AxiosError(error);
  }
};
