import React, { useState, createContext } from "react";
import { UserRoles } from "../types/enums";
import { Context } from "../types/interfaces";
import { Signature } from "../types/types";

export const AuthContext = createContext<Context | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(false);
  const [role, setRole] = useState(UserRoles.Standard);
  const [loading, setLoading] = useState(false);
  const [autostarting, setAutostarting] = useState<boolean>(true);
  const [authInProgress, setAuthInProgress] = useState<boolean>(false);
  const [userId, setUserId] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [signedDocuments, setSignedDocuments] = useState<Signature[]>([]);
  const [isPublicUser, setIsPublicUser] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        setAuthStatus,
        autostarting,
        setAutostarting,
        authInProgress,
        setAuthInProgress,
        userId,
        setUserId,
        role,
        setRole,
        loading,
        setLoading,
        orderRef,
        setOrderRef,
        signedDocuments,
        setSignedDocuments,
        isPublicUser,
        setIsPublicUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
